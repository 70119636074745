// import davidService from "@src/constants/davidServices";
// import URLS from "@src/constants/URLS";
// import { EXCEPTION_EVENTS } from "@src/analytics/ErrorLogger";

import { data as testimonailData } from "./config";

// const data = [
//   {
//     id: 4499,
//     resId: "",
//     addedDTime: 1659683242000,
//     currentCTC: 27,
//     totalExperience: 3.01,
//     name: "",
//     currentCompany: "Walmart",
//     currentDesignation: "SDE3",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Java,AWS,Spring Boot,Docker",
//     highestCollege: "Siddaganga Institute Of Technology (SIT), Tumakuru",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "62ecb5765eca3e1ce6ecf860",
//     userId: "62ecb5765eca3e1ce6ecf85f",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2019,
//     preferredLocations: "Bengaluru/ Bangalore",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits: "",
//     preferredCompanyTypes: "Public,B2C,B2B,Startup,Government,MNC,Product",
//     expectedCTC: 32,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "4",
//     accomplishmentsString:
//       "0.5 years in <b>Walmart Labs</b>;Has <b>startup</b> experience;Developed a socket based real time system for streaming data that is getting saved in the  in-memory Cache using Kafka, Stomp and Graphql.",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "0.5 years in <b>top product</b> companies",
//       "Has <b>startup</b> experience",
//       "Developed a socket based real time system for streaming data that is getting saved in the  in-memory Cache using Kafka, Stomp and Graphql.",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1659680940000,
//     commitedTime: 1659680280000,
//     previousExperiences: 1,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: false,
//     gender: "Male",
//   },
//   {
//     id: 5503,
//     resId: "",
//     addedDTime: 1661174258000,
//     currentCTC: 45,
//     totalExperience: 5,
//     name: "",
//     currentCompany: "Flipkart",
//     currentDesignation: "Software Development Engineer 2",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Python,Data Structures,Java",
//     highestCollege:
//       "Maulana Azad National Institute of Technology (NIT), Bhopal",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "5e9d911129dfed755da95e0b",
//     userId: "5e9d911129dfed755da95e0a",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2017,
//     preferredLocations: "Bengaluru/ Bangalore",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits: "Option to 'work from home'",
//     preferredCompanyTypes: "Product,MNC,Startup",
//     expectedCTC: 50,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "2",
//     accomplishmentsString:
//       "4 years in <b>Samsung & Flipkart</b>;Has a solid github repository with meaningful projects. Developed major key features of company's primary website and respective admin panel from scratch;",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "4 years in <b>top product</b> companies",
//       "Has a solid github repository with meaningful projects. Developed major key features of company's primary website and respective admin panel from scratch",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1661161620000,
//     commitedTime: 1660890180000,
//     previousExperiences: 2,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: true,
//     gender: "Male",
//   },
//   {
//     id: 2107,
//     resId: "",
//     addedDTime: 1655288005000,
//     currentCTC: 34,
//     totalExperience: 3,
//     name: "",
//     currentCompany: "Adobe",
//     currentDesignation: "Software Development Engineer 2",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Delhi",
//     skills: "JavaScript,Node.Js,Python,Data Structures,Algorithms",
//     highestCollege: "National Institute of Technology (NIT), Hamirpur",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "62a9aec529dfed33e317ea38",
//     userId: "62a9aec529dfed33e317ea37",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2019,
//     preferredLocations:
//       "Delhi,Bengaluru/ Bangalore,Hyderabad,Mumbai,Gurugram/ Gurgaon,Pune,Noida",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits: "",
//     preferredCompanyTypes: "Public,Startup,Government,Product,B2B,B2C,MNC",
//     expectedCTC: 44,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "3",
//     accomplishmentsString:
//       "1.5 years in <b>Adobe</b>;Improved performance bottleneck in diﬀerent ﬁle listings by implementing pagination which results in 4x faster loading of data for a US based  computer software company.",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "1.5 years in <b>top product</b> companies",
//       "Improved performance bottleneck in diﬀerent ﬁle listings by implementing pagination which results in 4x faster loading of data for a US based  computer software company.",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: false,
//     lastUpdatedOn: 1660307453000,
//     commitedTime: 1655287680000,
//     previousExperiences: 1,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: true,
//     gender: "Male",
//   },
//   {
//     id: 8557,
//     resId: "",
//     addedDTime: 1665396017000,
//     currentCTC: 32,
//     totalExperience: 4.04,
//     name: "",
//     currentCompany: "Microsoft",
//     currentDesignation: "SDE II",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Python,JavaScript,Scala,Java",
//     highestCollege: "Indian School Of Mines, Dhanbad",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 60,
//     noticePeriodLabel: "2 months",
//     email: "",
//     phone: "",
//     profileId: "6343ecca29dfed0aa92d50d5",
//     userId: "6343ecca29dfed0aa92d50d4",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2018,
//     preferredLocations:
//       "Hyderabad,Bengaluru/ Bangalore,Pune,Delhi,Gurugram/ Gurgaon",
//     preferredRoleTypes: "",
//     preferredBenefits:
//       "5 day work week,Stock/ESOPs/Equity/SARs,Option to 'work from home',Flexible work hours,Health care benefits,Pick-up/drop facility",
//     preferredCompanyTypes: "Startup,Product,B2C,B2B,MNC,SaaS",
//     expectedCTC: 45,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "1",
//     accomplishmentsString:
//       "4.5 years in <b>Microsoft & Samsung R&D</b>;Graduated from <b>Indian School Of Mines, Dhanbad</b>;Developed partner & solution recommendation system to serve recommendations for company's sellers and customers. Worked on an ML model to predict conversion propensity of lead & added 50% improvement.",
//     availabilityString:
//       "Notice period: <b>2 months</b>;Notice period is <b>negotiable</b>",
//     accomplishments: [
//       "4.5 years in <b>top product</b> companies",
//       "Graduated from <b>Tier 1</b> institute",
//       "Developed partner & solution recommendation system to serve recommendations for company's sellers and customers. Worked on an ML model to predict conversion propensity of lead & added 50% improvement.",
//     ],
//     availability: [
//       "Notice period: <b>2 months</b>",
//       "Notice period is <b>negotiable</b>",
//     ],
//     stableCandidate: true,
//     lastUpdatedOn: 1665405009000,
//     commitedTime: 1665395924000,
//     previousExperiences: 1,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "2 months notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: true,
//     gender: "Male",
//   },
//   {
//     id: 8941,
//     resId: "",
//     addedDTime: 1665722509000,
//     currentCTC: 35,
//     totalExperience: 5,
//     name: "",
//     currentCompany: "Microsoft",
//     currentDesignation: "Software Engineer II",
//     modifiedDTime: 1669712130000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Java,Node.Js,Python,Ruby on Rails (ROR)",
//     highestCollege: "National Institute of Technology (NIT), Durgapur",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 60,
//     noticePeriodLabel: "2 months",
//     email: "",
//     phone: "",
//     profileId: "5f88b87b29dfed74bd850ce2",
//     userId: "5f88b87b29dfed74bd850ce1",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Other Engineering",
//     highestGraduationYear: 2017,
//     preferredLocations: "Bengaluru/ Bangalore",
//     preferredRoleTypes: "",
//     preferredBenefits: "",
//     preferredCompanyTypes: "",
//     expectedCTC: 60,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "2",
//     accomplishmentsString:
//       "2 years in <b>Microsoft</b>;Has <b>startup</b> experience;Designed a complete content creation platform from scratch. Also, designed a system to help with game development on the cloud for different hardware devices using Azure, .Net & C#.",
//     availabilityString: "Notice period: <b>2 months</b>",
//     accomplishments: [
//       "2 years in <b>top product</b> companies",
//       "Has <b>startup</b> experience",
//       "Designed a complete content creation platform from scratch. Also, designed a system to help with game development on the cloud for different hardware devices using Azure, .Net & C#.",
//     ],
//     availability: ["Notice period: <b>2 months</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: null,
//     commitedTime: 1665651180000,
//     previousExperiences: 2,
//     assessed: true,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "2 months notice",
//     enthireScore: 9,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink:
//         "https://xcelerate.bigshyft.com/interview/feedback/1625/fab066c9",
//       overallTotalScore: 10,
//       overallScore: 9,
//       interviewCategory: "Algorithms and Coding",
//       strengths: [
//         {
//           name: "problem solving",
//           score: 9,
//         },
//         {
//           name: "communication",
//           score: 9,
//         },
//         {
//           name: "coding",
//           score: 9,
//         },
//       ],
//       assessed: true,
//     },
//     assessedDataString:
//       '{"performenceLevel":null,"reportLink":"https://xcelerate.bigshyft.com/interview/feedback/1625/fab066c9","overallTotalScore":10,"overallScore":9,"interviewCategory":"Algorithms and Coding","strengths":[{"name":"problem solving","score":9},{"name":"communication","score":9},{"name":"coding","score":9}],"assessed":true}',
//     enhancedProfileUpdated: false,
//     gender: "Male",
//   },
//   {
//     id: 9097,
//     resId: "",
//     addedDTime: 1665901786000,
//     currentCTC: 38,
//     totalExperience: 4,
//     name: "",
//     currentCompany: "ShareChat",
//     currentDesignation: " SDE-2 Backend",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Linux,Python,MySQL,JavaScript,Django",
//     highestCollege: "Indian Institute of Technology (IIT), Patna",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "5e3b789529dfed20ec1dc426",
//     userId: "5e3b789529dfed20ec1dc425",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2018,
//     preferredLocations: "Pune,Hyderabad,Bengaluru/ Bangalore,Delhi",
//     preferredRoleTypes: "Manager,Individual Contributor",
//     preferredBenefits:
//       "5 day work week,Option to 'work from home',Flexible work hours,Health care benefits,Pick-up/drop facility",
//     preferredCompanyTypes: "Government,Product,MNC,Startup",
//     expectedCTC: 50,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "2",
//     accomplishmentsString:
//       "1.5 years in <b>ShareChat</b>;Has <b>startup</b> experience;Designed and owned features like livestream-cohost construct, schedule-livestream, gamification\u0002framework, livestream explore feed, search within livestream for a social networking video sharing service provider. leading a team of 5 members.",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "1.5 years in <b>top product</b> companies",
//       "Has <b>startup</b> experience",
//       "Designed and owned features like livestream-cohost construct, schedule-livestream, gamification\u0002framework, livestream explore feed, search within livestream for a social networking video sharing service provider. leading a team of 5 members.",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1665923410000,
//     commitedTime: 1665738240000,
//     previousExperiences: 1,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: false,
//     gender: "Male",
//   },
//   {
//     id: 9273,
//     resId: "",
//     addedDTime: 1666076200000,
//     currentCTC: 24,
//     totalExperience: 4.03,
//     name: "",
//     currentCompany: "Flipkart",
//     currentDesignation: "Software Developer",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Python,Django,PostgreSQL,C++,Data Structures",
//     highestCollege:
//       "Indian Institute of Information Technology (IIIT), Allahabad",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "5e78c7805eca3e648627f387",
//     userId: "5e78c7805eca3e648627f386",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Information Technology",
//     highestGraduationYear: 2018,
//     preferredLocations: "Bengaluru/ Bangalore",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits:
//       "Health care benefits,Option to 'work from home',Stock/ESOPs/Equity/SARs,Flexible work hours,Pick-up/drop facility",
//     preferredCompanyTypes: "Product,B2C,B2B,MNC,Startup,SaaS",
//     expectedCTC: 30,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "2",
//     accomplishmentsString:
//       "2.5 years in <b>PixFirst & Flipkart</b>;Has <b>startup</b> experience;Developed Notiﬁcation Engine module to handle Notiﬁcations(Email, SMS, PUSH) and allowing a account holder to manually conﬁgure notiﬁcations to get notified since notify action triggers.",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "2.5 years in <b>top product</b> companies",
//       "Has <b>startup</b> experience",
//       "Developed Notiﬁcation Engine module to handle Notiﬁcations(Email, SMS, PUSH) and allowing a account holder to manually conﬁgure notiﬁcations to get notified since notify action triggers.",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1668342610000,
//     commitedTime: 1667912340000,
//     previousExperiences: 3,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: true,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: true,
//     gender: "Male",
//   },
//   {
//     id: 10613,
//     resId: "",
//     addedDTime: 1667820674000,
//     currentCTC: 18,
//     totalExperience: 3.04,
//     name: "",
//     currentCompany: "Samsung R&D",
//     currentDesignation: " Senior Software Engineer",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Java,Python,C++,HTML",
//     highestCollege: "Jadavpur University, Kolkata",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "6368db2b5eca3e2a8a2a8298",
//     userId: "6368db2b5eca3e2a8a2a8297",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2019,
//     preferredLocations:
//       "Bengaluru/ Bangalore,Remote/ Work from Home,Delhi,Gurugram/ Gurgaon",
//     preferredRoleTypes: "",
//     preferredBenefits: "",
//     preferredCompanyTypes: "Public,Startup,Government,Product,MNC,B2B,B2C",
//     expectedCTC: 25,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "2",
//     accomplishmentsString:
//       "3.5 years in <b>Samsung R&D</b>;Created a Phoneme Generation Engine using LSTM based Sequence Modelling, currently deployed on the Contacts Search App and has reduced the Recall error by 65% comparing with the previous  phonetic matching algorithm.",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "3.5 years in <b>top product</b> companies",
//       "Created a Phoneme Generation Engine using LSTM based Sequence Modelling, currently deployed on the Contacts Search App and has reduced the Recall error by 65% comparing with the previous  phonetic matching algorithm.",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1667820720000,
//     commitedTime: 1667816520000,
//     previousExperiences: 0,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: true,
//     gender: "Male",
//   },
//   {
//     id: 11765,
//     resId: "",
//     addedDTime: 1669122848000,
//     currentCTC: 20,
//     totalExperience: 3.04,
//     name: "",
//     currentCompany: "Walmart",
//     currentDesignation: "Software Engineer 3",
//     modifiedDTime: 1669559020000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Java,Spring Boot,PostgreSQL,Node.Js,Microservices",
//     highestCollege: "Presidency university bangalore ",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "605036aa29dfed3bba97bd83",
//     userId: "605036aa29dfed3bba97bd82",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2019,
//     preferredLocations:
//       "Hyderabad,Bengaluru/ Bangalore,Pune,Mumbai,Chennai,Delhi,Gurugram/ Gurgaon,Greater Noida,Noida",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits: "5 day work week",
//     preferredCompanyTypes: "Public,Startup,Product,B2C,B2B,MNC,SaaS",
//     expectedCTC: 24,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "4",
//     accomplishmentsString:
//       "1 year in <b>Walmart Labs</b>;Developed Online PIM Application from scratch primarily focused on Data Element Mapping and implementation.",
//     availabilityString:
//       "Notice period: <b>1 month</b>;Notice period is <b>negotiable</b>",
//     accomplishments: [
//       "1 year in <b>top product</b> companies",
//       "Developed Online PIM Application from scratch primarily focused on Data Element Mapping and implementation.",
//     ],
//     availability: [
//       "Notice period: <b>1 month</b>",
//       "Notice period is <b>negotiable</b>",
//     ],
//     stableCandidate: true,
//     lastUpdatedOn: null,
//     commitedTime: 1669121280000,
//     previousExperiences: 1,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: false,
//     gender: "Male",
//   },
//   {
//     id: 12553,
//     resId: "",
//     addedDTime: 1669791397000,
//     currentCTC: 21,
//     totalExperience: 3.06,
//     name: "",
//     currentCompany: "Amazon",
//     currentDesignation: "Software Development Engineer",
//     modifiedDTime: 1669889564000,
//     locationLabel: "Gurugram/ Gurgaon",
//     skills: "Java,Elasticsearch,Spring,PostgreSQL,Python",
//     highestCollege:
//       "Indian Institute of Information Technology (IIIT), Allahabad",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "5e01bc1929dfed10c0a95371",
//     userId: "5e01bc1929dfed10c0a95370",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Information Technology",
//     highestGraduationYear: 2019,
//     preferredLocations: "Delhi,Gurugram/ Gurgaon,Noida,Greater Noida",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits:
//       "5 day work week,Option to 'work from home',Stock/ESOPs/Equity/SARs",
//     preferredCompanyTypes: "Product,SaaS,MNC,Startup,B2B,B2C",
//     expectedCTC: 28,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "2",
//     accomplishmentsString:
//       "1.5 years in <b>Amazon</b>;Has <b>startup</b> experience;Designed & developed Role based Access Control System for physical retail store using Java and react. Hands-on experience in using data structure & algorithms.",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "1.5 years in <b>top product</b> companies",
//       "Has <b>startup</b> experience",
//       "Designed & developed Role based Access Control System for physical retail store using Java and react. Hands-on experience in using data structure & algorithms.",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: null,
//     commitedTime: 1669787880000,
//     previousExperiences: 2,
//     assessed: true,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: 9,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink:
//         "https://xcelerate.bigshyft.com/interview/feedback/2551/71310d9c",
//       overallTotalScore: 10,
//       overallScore: 9,
//       interviewCategory: "Algorithms and Coding",
//       strengths: [
//         {
//           name: "communication",
//           score: 10,
//         },
//         {
//           name: "coding",
//           score: 10,
//         },
//         {
//           name: "problem solving",
//           score: 8,
//         },
//       ],
//       assessed: true,
//     },
//     assessedDataString:
//       '{"performenceLevel":null,"reportLink":"https://xcelerate.bigshyft.com/interview/feedback/2551/71310d9c","overallTotalScore":10,"overallScore":9,"interviewCategory":"Algorithms and Coding","strengths":[{"name":"communication","score":10},{"name":"coding","score":10},{"name":"problem solving","score":8}],"assessed":true}',
//     enhancedProfileUpdated: false,
//     gender: "Male",
//   },
//   {
//     id: 13063,
//     resId: "",
//     addedDTime: 1670324562000,
//     currentCTC: 22.5,
//     totalExperience: 3.05,
//     name: "",
//     currentCompany: "Amazon",
//     currentDesignation: " Software Development Engineer ",
//     modifiedDTime: 1670568811000,
//     locationLabel: "Gurugram/ Gurgaon",
//     skills: "Java,MySQL,JavaScript,Spring,Python",
//     highestCollege: "Jamia Millia Islamia",
//     highestCourse: "MCA",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "5ef3c17529dfed35f6b5fc2b",
//     userId: "5ef3c17529dfed35f6b5fc2a",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2019,
//     preferredLocations: "Gurugram/ Gurgaon,Delhi,Bengaluru/ Bangalore",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits: "Option to 'work from home'",
//     preferredCompanyTypes: "",
//     expectedCTC: 30,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "3",
//     accomplishmentsString:
//       "0.5 years in <b>Amazon</b>;Designed and created scalable software products for telecom  company. And also written clean, functional code on the front-end  and back-end both.",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "0.5 years in <b>top product</b> companies",
//       "Designed and created scalable software products for telecom  company. And also written clean, functional code on the front-end  and back-end both.",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1670324520000,
//     commitedTime: 1670320860000,
//     previousExperiences: 2,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: true,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: true,
//     gender: "Female",
//   },
//   {
//     id: 4099,
//     resId: "",
//     addedDTime: 1659329534000,
//     currentCTC: 37,
//     totalExperience: 4,
//     name: "",
//     currentCompany: "Walmart",
//     currentDesignation: "software developer 3",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Java,Spring Boot,SQL,JavaScript,GIT",
//     highestCollege: "Birla Institute of Technology and Science (BITS), Pilani",
//     highestCourse: "M.Tech/ME",
//     noticePeriod: 60,
//     noticePeriodLabel: "2 months",
//     email: "",
//     phone: "",
//     profileId: "62e373b829dfed06bd530075",
//     userId: "62e373b829dfed06bd530074",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2020,
//     preferredLocations:
//       "Bengaluru/ Bangalore,Hyderabad,Remote/ Work from Home,Pune,Mumbai,Delhi,Gurugram/ Gurgaon,Greater Noida,Noida",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits: "5 day work week",
//     preferredCompanyTypes: "Public,Startup,Product,MNC,B2B,B2C,SaaS",
//     expectedCTC: 42,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "1",
//     accomplishmentsString:
//       "Graduated from <b>Birla Institute of Technology and Science (BITS), Pilani</b>;Designed and developed Flexible Layout Applications for the company that enable merchandisers within a consumer products  company to conduct Retail Visits at multiple outlets or store building up good revenue.",
//     availabilityString: "Notice period: <b>2 months</b>",
//     accomplishments: [
//       "Graduated from <b>Tier 1</b> institute",
//       "Designed and developed Flexible Layout Applications for the company that enable merchandisers within a consumer products  company to conduct Retail Visits at multiple outlets or store building up good revenue.",
//     ],
//     availability: ["Notice period: <b>2 months</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1660653014000,
//     commitedTime: 1659073680000,
//     previousExperiences: 0,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "2 months notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: -1,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString:
//       '{"performenceLevel":null,"reportLink":null,"overallTotalScore":0,"overallScore":-1,"interviewCategory":null,"strengths":null,"assessed":false}',
//     enhancedProfileUpdated: true,
//     gender: "Male",
//   },
//   {
//     id: 4211,
//     resId: "",
//     addedDTime: 1659369419000,
//     currentCTC: 45,
//     totalExperience: 3,
//     name: "",
//     currentCompany: "CRED",
//     currentDesignation: "Software Developer",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Java,HTML/CSS,C++,Python",
//     highestCollege: "Vellore Institute of Technology, Vellore",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "5e3498bc29dfed5f0a0c3998",
//     userId: "5e3498bc29dfed5f0a0c3997",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2019,
//     preferredLocations:
//       "Bengaluru/ Bangalore,Pune,Hyderabad,Delhi,Chennai,Mumbai,Gurugram/ Gurgaon",
//     preferredRoleTypes: "",
//     preferredBenefits:
//       "5 day work week,Pick-up/drop facility,Flexible work hours,Stock/ESOPs/Equity/SARs,Option to 'work from home'",
//     preferredCompanyTypes: "Public,B2C,B2B,MNC,Product,SaaS",
//     expectedCTC: 58.5,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "2",
//     accomplishmentsString:
//       "1.5 years in <b>CRED</b>;Has <b>startup</b> experience;Built Utility Payments from Scratch , which includes electricity , insurance , etc payments on a single platform.",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "1.5 years in <b>top product</b> companies",
//       "Has <b>startup</b> experience",
//       "Built Utility Payments from Scratch , which includes electricity , insurance , etc payments on a single platform.",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1659369540000,
//     commitedTime: 1659350880000,
//     previousExperiences: 1,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: -1,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString:
//       '{"performenceLevel":null,"reportLink":null,"overallTotalScore":0,"overallScore":-1,"interviewCategory":null,"strengths":null,"assessed":false}',
//     enhancedProfileUpdated: true,
//     gender: "Male",
//   },
//   {
//     id: 4761,
//     resId: "",
//     addedDTime: 1660038734000,
//     currentCTC: 30,
//     totalExperience: 5,
//     name: "",
//     currentCompany: "Samsung",
//     currentDesignation: "Software Engineer ",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Bengaluru/ Bangalore",
//     skills: "Java",
//     highestCollege: "Heritage Institute of Technology, Kolkata",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 60,
//     noticePeriodLabel: "2 months",
//     email: "",
//     phone: "",
//     profileId: "62eb619229dfed1372bdc861",
//     userId: "62eb619229dfed1372bdc860",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2014,
//     preferredLocations: "Bengaluru/ Bangalore",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits: "Option to 'work from home'",
//     preferredCompanyTypes: "",
//     expectedCTC: 50,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "4",
//     accomplishmentsString:
//       "3.5 years in <b>Samsung</b>;Built recommendation system also chatbots  feature for devices which boots more sales and awareness.",
//     availabilityString: "Notice period: <b>2 months</b>",
//     accomplishments: [
//       "3.5 years in <b>top product</b> companies",
//       "Built recommendation system also chatbots  feature for devices which boots more sales and awareness.",
//     ],
//     availability: ["Notice period: <b>2 months</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1659593160000,
//     commitedTime: 1659593100000,
//     previousExperiences: 0,
//     assessed: true,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "2 months notice",
//     enthireScore: 9,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: "<b>Their performance has been <u>Excellent!</u></b>",
//       reportLink:
//         "https://xcelerate.bigshyft.com/interview/feedback/8037/67e43fef",
//       overallTotalScore: 10,
//       overallScore: 9,
//       interviewCategory: "Algorithms and Coding",
//       strengths: [
//         {
//           name: "problem solving",
//           score: 9,
//         },
//         {
//           name: "communication",
//           score: 8,
//         },
//         {
//           name: "coding",
//           score: 8,
//         },
//       ],
//       assessed: true,
//     },
//     assessedDataString:
//       '{"performenceLevel":"<b>Their performance has been <u>Excellent!</u></b>","reportLink":"https://xcelerate.bigshyft.com/interview/feedback/8037/67e43fef","overallTotalScore":10,"overallScore":9,"interviewCategory":"Algorithms and Coding","strengths":[{"name":"problem solving","score":9},{"name":"communication","score":8},{"name":"coding","score":8}],"assessed":true}',
//     enhancedProfileUpdated: true,
//     gender: "Male",
//   },
//   {
//     id: 4807,
//     resId: "",
//     addedDTime: 1660106850000,
//     currentCTC: 25,
//     totalExperience: 3.04,
//     name: "",
//     currentCompany: "Amazon",
//     currentDesignation: "SDE 1",
//     modifiedDTime: 1669723903000,
//     locationLabel: "Gurugram/ Gurgaon",
//     skills: "Java,C++,Python,JavaScript,Data Structures",
//     highestCollege: "Indian Institute of Technology (IIT), Hyderabad",
//     highestCourse: "M.Tech/ME",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "62f2a0965eca3e5e59393693",
//     userId: "62f2a0965eca3e5e59393692",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2019,
//     preferredLocations: "Delhi,Gurugram/ Gurgaon,Noida,Greater Noida",
//     preferredRoleTypes: "Individual Contributor",
//     preferredBenefits:
//       "Option to 'work from home',Stock/ESOPs/Equity/SARs,Flexible work hours,Health care benefits,Pick-up/drop facility",
//     preferredCompanyTypes: "Startup,B2B,B2C,Product,MNC,SaaS",
//     expectedCTC: 35,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "2",
//     accomplishmentsString:
//       "3 years in <b>Amazon & MathWorks</b>;Has <b>startup</b> experience;Implemented mobile-number based login in addition to the existing email-based login for an online payment provider",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "3 years in <b>top product</b> companies",
//       "Has <b>startup</b> experience",
//       "Implemented mobile-number based login in addition to the existing email-based login for an online payment provider",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1661236140000,
//     commitedTime: 1660067998000,
//     previousExperiences: 2,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: false,
//     gender: "Male",
//   },
//   {
//     id: 5207,
//     resId: "",
//     addedDTime: 1660823408000,
//     currentCTC: 32,
//     totalExperience: 4,
//     name: "",
//     currentCompany: "Adobe",
//     currentDesignation: "full stack developer",
//     modifiedDTime: 1669294488000,
//     locationLabel: "Delhi",
//     skills: "Java,Python,Data Structures,Algorithms,React.Js",
//     highestCollege: "Delhi College of Engineering (DCE), Delhi",
//     highestCourse: "B.Tech/BE",
//     noticePeriod: 30,
//     noticePeriodLabel: "1 month",
//     email: "",
//     phone: "",
//     profileId: "62fe165029dfed7136af5103",
//     userId: "62fe165029dfed7136af5102",
//     functionalArea: "Software Engineering",
//     jobRole: "Backend",
//     highestSpecialization: "Computer Science",
//     highestGraduationYear: 2018,
//     preferredLocations: "Bengaluru/ Bangalore,Gurugram/ Gurgaon,Delhi,Noida",
//     preferredRoleTypes: "",
//     preferredBenefits: "Option to 'work from home',5 day work week",
//     preferredCompanyTypes: "Product,MNC,B2B,Startup",
//     expectedCTC: 41,
//     jobSearchStatus: "ACTIVELY_LOOKING",
//     expectedCTCRange: null,
//     servingNoticePeriod: null,
//     servingNoticePeriodUpdateDate: null,
//     servingNoticePeriodEndDate: null,
//     servingNoticePeriodInDays: null,
//     naukriNoticePeriodValue: null,
//     servingNoticePeriodId: null,
//     collegeTier: "1",
//     accomplishmentsString:
//       "1 year in <b>Adobe</b>;Graduated from <b>Delhi College of Engineering (DCE), Delhi</b>;Delivered cost saving of approx. 647,800 Euro by creating capacity of 32 vendor resources for a leading global investment bank. Ranked among the top 0.004% candidates in JEE-MAINS",
//     availabilityString: "Notice period: <b>1 month</b>",
//     accomplishments: [
//       "1 year in <b>top product</b> companies",
//       "Graduated from <b>Tier 1</b> institute",
//       "Delivered cost saving of approx. 647,800 Euro by creating capacity of 32 vendor resources for a leading global investment bank. Ranked among the top 0.004% candidates in JEE-MAINS",
//     ],
//     availability: ["Notice period: <b>1 month</b>"],
//     stableCandidate: true,
//     lastUpdatedOn: 1660820040000,
//     commitedTime: 1660819920000,
//     previousExperiences: 1,
//     assessed: false,
//     interviewCategory: null,
//     reportUrl: null,
//     performenceLevel: null,
//     noticePeriodVal: "1 month notice",
//     enthireScore: -1,
//     lastWorkingDay: null,
//     topProductCompany: false,
//     commitedAAA: true,
//     managementRole: false,
//     assessedData: {
//       performenceLevel: null,
//       reportLink: null,
//       overallTotalScore: 0,
//       overallScore: 0,
//       interviewCategory: null,
//       strengths: null,
//       assessed: false,
//     },
//     assessedDataString: null,
//     enhancedProfileUpdated: false,
//     gender: "Male",
//   },
// ];

const fetchCarouselAAAcandidates = async ({ setCardsData, setIsApiFailed }) => {
  //   let url = URLS.getAAACandidateListData;
  //   url += "?isCarousel=true";
  //   try {
  //     const result = await davidService.post(url, {
  //       page: 1,
  //       size: 16,
  //       sortCriteria: [],
  //       queryFields: []
  //     });
  //     if (result.status >= 200 && result.status < 300) {
  setCardsData(testimonailData);
  //     } else {
  //       setIsApiFailed(true);
  //     }
  //   } catch (error) {
  //     setIsApiFailed(true);
  //     window.__bglogger.on("error", {
  //       exceptionName: EXCEPTION_EVENTS.API_CATCH,
  //       fn: "fetchCarouselAAAcandidates",
  //       "api endpoint": url,
  //       errorObj: error
  //     });
  //   }
};

export default fetchCarouselAAAcandidates;
